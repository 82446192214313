<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <base-card>
            <v-card-title> Current Pax Company </v-card-title>
            <v-card-text>
              {{ currentCompany.name }}
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <base-card>
            <v-card-title> Search Trevor companies </v-card-title>
            <v-card-text>
              <v-autocomplete
                :search-input.sync="search"
                :loading="isLoading"
                v-model="selectedTrevorCompany"
                hide-details
                :items="trevorCompanies"
                hide-no-data
                item-text="name"
                item-value="id"
                placeholder="Search Trevor Companies"
                prepend-icon="mdi-database-search"
                return-object
                @keyup.enter.stop="trevorCompanySearchEnter"
                @keyup.esc.stop="showNextCompany"
              >
              </v-autocomplete>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="showDialog"
      width="500"
      @keydown.enter="dialogPressedEnter"
      @keydown.esc="cancelAll"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedTrevorCompany.name }}
        </v-card-title>

        <v-card-text>
          Are you sure you want to assign {{ currentCompany.name }} to
          {{ selectedTrevorCompany.name }}?
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$courier.Pax8.SyncAndGetDiffCompanies().then((x) => {
      x.json().then((r) => {
        this.companies = r;
        this.showNextCompany();
      });
    });
  },
  watch: {
    search() {
      //this.selectedTrevorCompany = {};

      if (this.isLoading) {
        return;
      }

      this.searchTrevorCompanies();
    },
  },
  methods: {
    cancelAll() {
      this.selectedTrevorCompany = {};
      this.search = "";
    },
    showNextCompany() {
      if (this.companies && this.currentIndex < this.companies.length) {
        this.currentCompany = this.companies[this.currentIndex];
        this.currentIndex++;
      }else{
          alert('No More Companies')
          this.currentIndex = 0;
          this.currentCompany = this.companies[this.currentIndex];
      }
    },
    searchTrevorCompanies() {
      this.isLoading = true;
      this.$courier.Companies.getData(1, 25, this.search).then((x) => {
        this.trevorCompanies = x.payload;
        this.isLoading = false;
      });
    },
    trevorCompanySearchEnter() {
      if (this.selectedTrevorCompany) {
        this.showDialog = true;
      }
    },
    dialogPressedEnter() {
      this.$courier.Companies.withId(this.selectedTrevorCompany.id).PaxLinks.add(
        {
          Pax8Id: this.currentCompany.id,
          Pax8Name: this.currentCompany.name,
        }
      ).then((x) => {
        this.showNextCompany();
        this.showDialog = false;
        this.search = "";
      });
    },
  },
  data() {
    return {
      showDialog: false,
      trevorCompanies: [],
      isLoading: false,
      selectedTrevorCompany: {},
      search: "",
      currentIndex: 0,
      currentCompany: {},
      companies: [],
    };
  },
};
</script>

<style>
</style>